export enum EventEnum {
  CLICK_TO_LIKE = 'click:clique-[gostei]',
  DISCARD_RECOMMENDATION = 'click:descartar-recomendacao',
  BACK_TO_RECOMMENDATION = 'click:voltar-para-recomendacao',
  CLICK_DONT_LIKE = 'click:nao-gostei',
  LOADING_RECOMMENDATIONS = 'carregando-recomendacoes',
  GENERATE_MESSAGE = 'click:gerar-mensagem',
  CLOSE_RECOMMENDATION = 'click:fechar-recomendacao',
  SEND_MESSAGE = 'click:enviar-mensagem',
  GENERATE_NEW_MESSAGE = 'click:gerar-nova-mensagem',
}
